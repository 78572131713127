import { Typography } from "@material-ui/core";
import React from "react";

export default function Vision (props) {
	return (
		<div id="vision">
			<div className="standard-container">
				<div>
                    &nbsp;
					<Typography className="title center">
                        La nostra vision
					</Typography>
					<div>
						<Typography className="justify ">
                            Puntiamo a trasmettere alle aziende - che sceglieranno di rivolgersi a noi - la convinzione
                            che
                            innovazione del paradigma business e sviluppo tecnologico devono viaggiare di pari passo se
                            si vuole
                            eccellere nel proprio settore di riferimento.
							{" "}
						</Typography>
						<Typography className="justify">
                            Crediamo convintamente che il raggiungimento dei nostri obiettivi non possa sussistere senza

                            attingere al genio e all’entusiasmo dei giovani talenti italiani, guardando particolarmente
                            a quelli

                            presenti sul territorio – geni incompresi - costretti quasi sempre a trovare fortuna lontano
                            da casa

                            e quindi di riuscire a concorrere attivamente alla rinascita della
							{" "}
							<strong>
                                “regione che non esiste”
							</strong>
							{" "}
                            con

                            l’ambizione di creare ed esportare un modello di innovazione sviluppato sia in Italia che
                            all’estero.
						</Typography>
					</div>
				</div>
			</div>
		</div>
	);
}
