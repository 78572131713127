import git from "./git.png";
import mongo from "./mongo.png";
import mysql from "./mysql.png";
import php from "./php.png";
import react from "./react.png";
import python from "./python.png";
import express from "./Express.png";
import node from "./node.png";
import aws from "./aws.png";
import hibernate from "./hibernate.png";
import spring from "./spring.png";
import ci from "./codeIgniter.png";
import html from "./html.png";
import js from "./js.png";
import css from "./css.png";
import magento from "./magento.png";
import wordpress from "./wordpress.png";
import ionic from "./ionic.png";
import angular from "./angular.svg";
import cordova from "./cordova.png";
import ios from "./ios.png";
import prestashop from "./prestashop.png";
import android from "./android.png";
import rn from "./rn.png";
import woo from "./woocommerce-wordpress.png";
import unity from "./unity.svg";
import ue from "./ue.png";
import blender from "./blender.png";
import cura from "./cura.png";
import sketch from "./sketch.png";
import godot from "./godot.png";

export const lorem = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur";

export const gitLogo = git;
export const mongoLogo = mongo;
export const mysqlLogo = mysql;
export const pythonLogo = python;
export const phpLogo = php;
export const reactLogo = react;
export const expressLogo = express;
export const nodeLogo = node;
export const ciLogo = ci;
export const springLogo = spring;
export const hibernateLogo = hibernate;
export const androidLogo = android;
export const awsLogo = aws;
export const rnLogo = rn;
export const cssLogo = css;
export const htmlLogo = html;
export const jsLogo = js;
export const magentoLogo = magento;
export const wordpressLogo = wordpress;
export const prestashopLogo = prestashop;
export const ionicLogo = ionic;
export const cordovaLogo = cordova;
export const angularLogo = angular;
export const iosLogo = ios;
export const curaLogo = cura;
export const blenderLogo = blender;
export const ueLogo = ue;
export const sketchLogo = sketch;
export const unityLogo = unity;
export const godotLogo = godot;
export const wooLogo = woo;
// export const git = require("./git.png")
