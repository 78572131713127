import React from "react";

export default function ToolBar () {
	return (
		<div className="toolbar">
			<div
				className="toolbar-content"
				style={{ height: 80 }}/>
		</div>
	);
}
